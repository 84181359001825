import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import logo from '../images/logo.png'

const IndexPage = () => (
  <Layout>
    <Seo title="Animal Behavior Evaluation Platform" />
    <div style={{ marginTop: '4em' }}>
      <img
        src={logo}
        width={200}
        alt="Eleven Maze Logo"
        style={{ marginBottom: `0.1em` }}
      />
      <h1 style={{ fontSize: '3em', textShadow: '-3px 3px 3px rgba(0, 0, 0, 0.5)' }}>
        Eleven Maze
      </h1>
      <p style={{ fontSize: '1.1em' }}>
        Animal Behavior Evaluation Platform
      </p>
      <p>
        <a href="mailto:adrian@elevenpuzzles.com" style={{ color: '#fff' }}>Contact us</a>
      </p>
    </div>
  </Layout>
)

export default IndexPage
